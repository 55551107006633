import { createReactQueryHooks } from "@trpc/react";
import { AppRouter } from "../server";

// TODO(fwallace): How are we handling X-CSRF-TOKEN? This is what we did for GraphQL:
// const httpLink = createHttpLink({
//     uri: "/api/graphql",
//   });

//   const authLink = setContext((_, { headers }) => {
//     // Get the authentication token from local storage if it exists
//     const token = localStorage.getItem("token");
//     // Return the headers to the context so httpLink can read them
//     return {
//       headers: {
//         ...headers,
//         "X-CSRF-TOKEN": token || "",
//       },
//     };
//   });

//   export const apolloClient = new ApolloClient({
//     link: authLink.concat(httpLink),
//     cache: new InMemoryCache(),
//   });

// export a React hook that knows how to use all our trpc queries/mutations
// the magic here is passing the AppRouter type, so the hook can infer the API types/inputs/outputs
export const trpc = createReactQueryHooks<AppRouter>();
