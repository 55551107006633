import { UserProfile } from "@auth0/nextjs-auth0";
import { Box, FlexProps, HStack, Spinner } from "@chakra-ui/react";
import { CreateAccountButton } from "./Budget/Account/CreateAccountButton";
import { BudgetTabs } from "./Budget/BudgetTabs";
import { Logo } from "./Logo";
import { RedirectButton } from "./RedirectButton";
import { UserMenu } from "./User/UserMenu";

interface HeaderProps {
  user?: UserProfile;
  userLoading?: Boolean;
}

export const Header = ({ user, userLoading }: HeaderProps) => {
  const flexProps: FlexProps = {
    as: "nav",
    align: "center",
    justify: "space-between",
    wrap: "wrap",
    w: "100%",
    mb: 0,
    py: 2,
    px: 8,
    bg: ["white"],
    color: ["teal.500", "teal.500", "primary.700", "primary.700"],
    borderBottom: "1px",
  };

  // If we have a user, return a different header!
  if (user) {
    return (
      <Box {...flexProps}>
        <HStack justifyContent="space-between">
          <BudgetTabs />
          <HStack spacing={4}>
            <CreateAccountButton />
            <UserMenu user={user} />
          </HStack>
        </HStack>
      </Box>
    );
  }

  const userLoginComponent = userLoading ? (
    <Spinner />
  ) : (
    <RedirectButton label="Login/Sign Up" href="/api/auth/login" />
  );
  return (
    <Box {...flexProps}>
      <HStack justifyContent="space-between">
        <Logo />
        {userLoginComponent}
      </HStack>
    </Box>
  );
};
