import { UserProfile } from "@auth0/nextjs-auth0";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";

interface Props {
  user: UserProfile;
}

export const UserMenu = ({ user }: Props) => {
  let userText = "Your Profile";
  if (user.name) {
    const userNameParts = user.name.split(" ");
    userText = userNameParts.map((s) => s.charAt(0).toUpperCase()).join("");
  }

  return (
    <Menu gutter={16} id="user-dropdown-menu">
      <MenuButton
        as={Button}
        colorScheme="teal"
        rightIcon={<ChevronDownIcon />}
      >
        {userText}
      </MenuButton>
      <MenuList>
        <MenuGroup>
          <MenuItem as="a" href="/user/preferences">
            Preferences
          </MenuItem>
          <MenuItem as="a" href="/user/linked_accounts">
            Linked Accounts
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup>
          <MenuItem as="a" href="/api/auth/logout">
            Logout
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
