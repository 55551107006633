import { Box, BoxProps, Text } from "@chakra-ui/react";
import Link from "next/link";
import React from "react";

export const Logo = (props: BoxProps) => {
  return (
    <Box {...props}>
      <Link href="/" passHref>
        <Text as="a" fontSize="2xl" fontWeight="bold">
          Limitless
        </Text>
      </Link>
    </Box>
  );
};
