import { AddIcon } from "@chakra-ui/icons";
import { Button, Spinner, useToast } from "@chakra-ui/react";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";
import { trpc } from "../../../lib/trpc";

// TODO(fwallace): Let users manually add accounts
// TODO(fwallace): Put plaid api key, client id, and base url (https://api.mx.com) in github secrets/vercel env
export const CreateAccountButton = () => {
  const toast = useToast();

  // Get a Plaid link token
  const {
    data: tokenData,
    isLoading: tokenLoading,
    error: tokenError,
  } = trpc.useQuery(["users.get_link_token"]);

  const exchangeToken = trpc.useMutation(["users.exchange_public_token"], {
    onSuccess: (data) => {
      toast({
        position: "top",
        title: `Successfully linked account! It may take a few minutes to sync transactions.`,
        status: "success",
        isClosable: true,
      });
    },
    onError: (error) => {
      toast({
        position: "top",
        title: `Unable to link account.`,
        description: error.message,
        status: "error",
        isClosable: true,
      });
    },
  });

  // The usePlaidLink hook manages Plaid Link creation. It does not return a destroy function; instead, on unmount it
  // automatically destroys the Link instance.
  const config: PlaidLinkOptions = {
    onSuccess: async (public_token, metadata) => {
      // Exchange the public token for an access token.
      await exchangeToken.mutateAsync({ public_token });
    },
    // TODO(fwallace): Handle errors. See https://plaid.com/docs/link/web/#onexit
    onExit: (err, metadata) => {},
    // TODO(fwallace): Handle events. See https://plaid.com/docs/link/web/#onevent
    onEvent: (eventName, metadata) => {},
    token: tokenData || null,
    // TODO(fwallace): OAuth
    //required for OAuth; if not using OAuth, set to null or omit:
    // receivedRedirectUri: window.location.href,
  };
  const { open, exit, ready } = usePlaidLink(config);

  if (tokenError) {
    throw tokenError;
  }

  if (tokenLoading || !tokenData) {
    return <Spinner />;
  }

  return (
    <Button
      leftIcon={<AddIcon />}
      colorScheme="teal"
      variant="outline"
      onClick={() => open()}
      disabled={!ready}
    >
      Account
    </Button>
  );
};
