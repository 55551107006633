import { Button, HStack } from "@chakra-ui/react";
import Link from "next/link";
import { useRouter } from "next/router";

export const BudgetTabs = () => {
  const router = useRouter();

  const tabs = [
    { label: "My Budget", href: "/budget/dashboard" },
    { label: "Transactions", href: "/budget/transactions" },
  ];

  return (
    <HStack as="nav" spacing="1">
      {tabs.map((tab, i) => (
        <Link key={i} href={tab.href} passHref>
          <Button
            colorScheme="teal"
            isActive={router.pathname == tab.href}
            variant="ghost"
          >
            {tab.label}
          </Button>
        </Link>
      ))}
    </HStack>
  );
};
