import { Button, ThemingProps } from "@chakra-ui/react";
import Link, { LinkProps } from "next/link";
import React from "react";

interface Props extends LinkProps {
  label: String;
  variant?: String;
  leftIcon?: React.ReactElement;
}

export const RedirectButton = ({
  label,
  variant = "solid",
  leftIcon,
  ...props
}: Props) => {
  const buttonVariant = variant as ThemingProps<"Button">["variant"];
  return (
    <Link {...props} passHref>
      <Button colorScheme="teal" variant={buttonVariant} leftIcon={leftIcon}>
        {label}
      </Button>
    </Link>
  );
};
