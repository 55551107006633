import { useUser } from "@auth0/nextjs-auth0";
import { Box, VStack } from "@chakra-ui/react";
import Head from "next/head";
import { ReactNode } from "react";
import { Header } from "./Header";

interface LayoutProps {
  // user?: UserProfile;
  // loading?: Boolean;
  children?: ReactNode;
}

export const Layout = ({ children }: LayoutProps) => {
  const { user, error, isLoading } = useUser();

  return (
    <>
      <Head>
        <title>Limitless</title>
      </Head>

      <Box bg="gray.200" minHeight="100vh">
        <VStack spacing="4">
          <Header user={user} userLoading={isLoading} />

          <main>
            <div className="container">{children}</div>
          </main>
        </VStack>
      </Box>
    </>
  );
};
