import { UserProvider } from "@auth0/nextjs-auth0";
import { ChakraProvider } from "@chakra-ui/react";
import { withTRPC } from "@trpc/next";
import type { AppProps } from "next/app";
import superjson from "superjson";
import { Layout } from "../components/Layout";
import { theme } from "../components/theme";
import { getBaseUrl } from "../lib";
import { AppRouter } from "../server";
import "../styles/globals.css";

function LimitlessApp({ Component, pageProps }: AppProps) {
  // TODO(fwallace)
  // @Zeb I can't get the user using `useUser` here because it has to be wrapped inside of the `UserProvider`. This
  // means that I'm still running into the issue where the app momentarily renders the incorrect header when loading a
  // new page
  return (
    <ChakraProvider theme={theme}>
      <UserProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </UserProvider>
    </ChakraProvider>
  );
}

export default withTRPC<AppRouter>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    const url = `${getBaseUrl()}/api/trpc`;

    return {
      url,
      /**
       * @link https://react-query.tanstack.com/reference/QueryClient
       */
      // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
      transformer: superjson,
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   */
  ssr: true,
})(LimitlessApp);
