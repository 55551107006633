const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

// Format a number, and replace a hyphen with a minus sign.
export const currencyFormatter = (num: number) => {
  return formatter.format(num).replace(/^(-)/, "−");
};

export const formatCategoryPeriodName = (period: string) => {
  return `${period.slice(0, 1).toUpperCase()}${period.slice(1).toLowerCase()}`;
};

export const formatDate = (date: Date) => {
  return date.toLocaleDateString();
};

export const dateToISOLocaleDateString = (date: Date) => {
  // Javascript's handling of dates is beyond awful. For starters, `toLocaleDateString` doesn't return an ISO8601
  // compliant string, and `toISOString` returns the string in UTC time (because JS makes everything UTC time). Also,
  // javascript indexes month from 0, rather than 1. So, to format an ISO compliant date string in _*local time*_, we
  // have to do the parsing below.
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
    2,
    "0"
  )}-${String(date.getDate()).padStart(2, "0")}`;
};

export function getBaseUrl() {
  if (typeof window !== "undefined") {
    return "";
  }

  switch (process.env.VERCEL_ENV) {
    case "preview":
      return `https://${process.env.VERCEL_URL}`;
    case "production":
      return `https://limitlesslife.app`;
    default:
      return `http://localhost:${process.env.PORT ?? 3000}`;
  }
}
